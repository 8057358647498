import "../fonts/CocoGothic-Bold.ttf"
import "../fonts/CocoGothic-Light_trial.ttf"
import "../fonts/Posterama2001W04Light.ttf"
import "../styles/index.css"
import HomePage from "./home"
import React from "react"

export default function Home() {
  return <HomePage />
}
